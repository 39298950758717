<template>
    <div>
        <vx-card title="Supplier Payment Receive">
            <vs-tabs :color="colorx" v-model="tabs">
                <vs-tab @click="colorx = 'danger'" label="Open">
                    <div class="con-tab-ejemplo">
                        <open/>
                    </div>
                </vs-tab>
            </vs-tabs>
        </vx-card>
    </div>
</template>
<script>
import open from "./open/open.vue";
// import apply from "./apply/apply.vue";
// import cancel from "./cancel/cancel.vue";
// import reconcile from "./reconcile/reconcile.vue";
// import reconciled from "./reconciled/reconcile.vue";
// import reversed from "./reversed/reversed.vue";
// import done from "./done.vue";

export default {
    components: {
        open,
        // apply,
        confirm
        // cancel,
        // reconcile,
        // reversed,
        // reconciled
        // done,
    },
    data:() => ({
        colorx: "danger",
        tabs: 0,
    })
}
</script>